module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pro Paintball, Carlisle, Cumbria","short_name":"Pro Paintball","start_url":"/","background_color":"#2cbdff","theme_color":"#2cbdff","display":"standalone","icon":"/home/runner/work/propaintball.co.uk/propaintball.co.uk/src/images/logo.webp","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8edb98db4b0c859e62a969d1b6e6dfe9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/home/runner/work/propaintball.co.uk/propaintball.co.uk/src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
